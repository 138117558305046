import React, { useEffect } from "react";
import "./styles/group3.css";

function CombinedPage() {
  useEffect(() => {
    // Dynamically create and add the script for the chatbot
    const script = document.createElement("script");
    script.innerHTML = `
      !function(w, d, s, ...args){
        var div = d.createElement('div');
        div.id = 'aichatbot';
        d.body.appendChild(div);
        w.chatbotConfig = args;
        var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s);
        j.defer = true;
        j.type = 'module';
        j.src = 'https://aichatbot.sendbird.com/index.js';
        f.parentNode.insertBefore(j, f);
      }(window, document, 'script', 'D20E9B89-B22C-476A-974B-23EF9536F266', 'FdHpgLCNfkqnHKbzezS3Z', {
        apiHost: 'https://api-cf-us-3.sendbird.com',
      });
    `;
    document.body.appendChild(script);

    return () => {
      const chatbotDiv = document.getElementById("aichatbot");
      if (chatbotDiv) chatbotDiv.remove();
      script.remove();
    };
  }, []); // Runs only once

  return (
    <div style={{ fontSize: "1.2em", fontWeight: "bold", marginTop: "10px" }}>
      <header className="header">
        <h1>Welcome to the Support Page</h1>
        <p  className="header-subtext">
          Get support by chatting with us or filling out the form below. We’re
          here to help!
        </p>
      </header>

      <div className="content">
        {/* Form Section */}
        <div className="form-card">
          <h2>Contact Us</h2>
          <form
            action="https://blackforce123--c8group3.sandbox.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00DAu000006pigH"
            method="POST"
          >
            <input type="hidden" name="orgid" value="00DAu000006pigH" />
            <input
              type="hidden"
              name="retURL"
              value="https://support.blackforce.ca/thank-you.html"
            />

            <label htmlFor="name">Contact Name</label>
            <input
              id="name"
              maxLength="80"
              name="name"
              size="20"
              type="text"
              required
            />
            <br />

            <label htmlFor="email">Email</label>
            <input
              id="email"
              maxLength="80"
              name="email"
              size="20"
              type="email"
              required
            />
            <br />

            <label htmlFor="phone">Phone</label>
            <input
              id="phone"
              maxLength="40"
              name="phone"
              size="20"
              type="tel"
              pattern="[0-9]{10,}"
              title="Please enter a valid phone number with only digits."
              required
            />
            <br />

            <label htmlFor="reason">Request Type</label>
            <select
              id="00NAu000005mvqj"
              name="00NAu000005mvqj"
              title="Request type"
            >
              <option value="">--None--</option>
              <option value="Technical issues">Technical issues</option>
              <option value="Program Enquiry">Program Enquiry</option>
              <option value="Training">Training</option>
              <option value="Others">Others</option>
            </select>
            <br />
            <br />

            <label htmlFor="subject">Subject</label>
            <input
              id="subject"
              maxLength="80"
              name="subject"
              size="20"
              type="text"
            />
            <br />

            <label htmlFor="description">Description</label>
            <textarea name="description"></textarea>
            <br />

            <input
              type="submit"
              name="submit"
              value="Submit"
              className="submit-button"
            />
          </form>
        </div>

        {/* Chatbot Section */}
        <div id="aichatbot" style={{ marginTop: "20px" }}></div>
      </div>

      <footer className="footer">
        <p>Email us at: internsgroup3@gmail.com</p>
        <p>Phone: (416)-826-6760</p>
        <p>© 2024 Support Team.</p>
      </footer>
    </div>
  );
}

export default CombinedPage;
